
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useEffect, useState } from 'react'
const Alert = () => {
	const [visible, setVisible] = useState(false)
	const [content, setcontent] = useState(false)
	const [level, setlevel] = useState(false)
	useEffect(() => {
		window.alert = (content, level) => {
			setVisible(true)
			setcontent(content)
			setlevel(level)
		}
	}, [])
	return (
		<>
			<CModal
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<CModalHeader>
					{/* <div style={{fontSize:36, cursor:'pointer'}} onClick={()=>{setVisible(false)}}>&times;</div> */}
				</CModalHeader>
				<CModalBody>
					<div className={level == "success" ? "alert alert-success" : (level == "warning" ? "alert alert-warning" : "alert alert-danger")} role="alert">{content}</div>
				</CModalBody>
				{/* <CModalFooter>
					<CButton color="secondary" onClick={() => setVisible(false)}>
						Close
					</CButton>
				</CModalFooter> */}
			</CModal>
		</>
	)
}

export default Alert