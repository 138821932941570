import { isset } from "./default"

export const error_handle = (error)=>{
    console.log(error)
    try {
        if(error.response){
            let log = JSON.stringify(error.response.data.detail)
            if(error.response.status == 401 || error.response.status == 403){
                window.location.href = `#/login?next=${encodeURI(window.location.href)}&error=${log}`
                return
            }
            
            window.alert(log)
            return
        }
        if(isset(error.result)){
            if(!error.result){
                window.alert(error.message)
                return
            }
        }
            
    } catch (error2) {
        console.log(error2)
        window.alert(error2)
    }
    
}