import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'core-js'

import App from './App'
import store from './store'
function clearNumber(string){
	if(!string) return 0;
	return string.toString().replace(/[^\d\-\.]/g,'');
}

global.formatNumber = (num) => {
	if(!num) return 0;
	num = clearNumber(num);
	return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>,
)
