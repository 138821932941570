import React, { Suspense, useEffect, useRef } from 'react'
import { HashRouter as Router, Route, Routes, RouterProvider, createHashRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'
import AuthChecker from './components/authen/AuthChecker'
import Loading from './components/common/Loading'
import Alert from './components/common/Alert'
import Question from './components/common/Question'


// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  let localStorageItemName = 'coreui-free-react-admin-template-theme'
  const { isColorModeSet, setColorMode } = useColorModes(localStorageItemName)
  const storedTheme = localStorage.getItem(localStorageItemName)


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    } else {
      setColorMode(storedTheme ?? 'light')
    }

    // if (isColorModeSet()) {
    //   return
    // }
    // console.log(storedTheme)
    // setColorMode(storedTheme)
    // console.log(router)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const router = createHashRouter([

    {
      path: "login",
      element: <Login />,
    },
    {
      path: "*",
      element: <AuthChecker><DefaultLayout /></AuthChecker>,
    },

  ]);

  return <Suspense
    fallback={
      <div className="pt-3 text-center">
        <CSpinner color="primary" variant="grow" />
      </div>
    }
  >
    <RouterProvider router={router}></RouterProvider>
    <Loading></Loading>
    <Alert></Alert>
    <Question></Question>
  </Suspense>

  // return (
  //   <Router>
  //     <Suspense
  //       fallback={
  //         <div className="pt-3 text-center">
  //           <CSpinner color="primary" variant="grow" />
  //         </div>
  //       }
  //     >
  //       <Routes>
  //         <Route exact path="/login" name="Login Page" element={<Login />} />
  //         {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
  //         <Route exact path="/404" name="Page 404" element={<Page404 />} />
  //         <Route exact path="/500" name="Page 500" element={<Page500 />} />
  //         <Route path="*" name="Home" element={<AuthChecker><DefaultLayout /></AuthChecker>} />
  //       </Routes>
  //     </Suspense>
  //   </Router>
  // )
}

export default App