
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useRef, useEffect, useState } from 'react'
const Question = () => {
	const [visible, setVisible] = useState(false)
	const [content, setcontent] = useState(false)
	const [level, setlevel] = useState(false)
	const actionRef = useRef(null)
	useEffect(() => {
		window.question = (content, level='warning') => {
			setVisible(true)
			setcontent(content)
			setlevel(level)
			actionRef.current = null
			return new Promise((resolve, reject)=>{
				actionRef.current = resolve
			})
		}
	}, [])
	return (
		<>
			<CModal
				visible={visible}
				onClose={() => setVisible(false)}
			>
				<CModalBody>
					<div className={level == "success" ? "alert alert-success" : (level == "danger" ? "alert alert-danger" : "alert alert-warning")} role="alert">{content}</div>
				</CModalBody>
				<CModalFooter>
					<CButton color="primary" onClick={() => {
						setVisible(false)
						if(actionRef.current){
							actionRef.current(false)
						}
					}}>Cancel</CButton>
					<CButton color="secondary" onClick={() => {
						setVisible(false)
						if(actionRef.current){
							actionRef.current(true)
						}
					}}>Continue</CButton>
				</CModalFooter>
			</CModal>
		</>
	)
}

export default Question