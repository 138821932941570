import axios from "axios";
import { error_handle } from "../helpers/error";
class authen {
    getUser() {
        return axios.request({
            url: '/authentication/authen/getUser',
            method: 'get',
        })

            .then(response => {
                response = response['data'];
                return response;
            })

            .catch((error) => {
                error_handle(error)
            })

    }

    login(username, password, nextLink, captchaData) {

        const formData = new URLSearchParams();
        formData.append('username', username);
        formData.append('password', password);
        if (captchaData) {
            formData.append('captcha', captchaData['captcha']);
            formData.append('captcha_hash', captchaData['hash']);
        }

        return axios.request({
            url: '/authentication/authen/login',
            method: 'post',
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })

            .then(response => {
                response = response['data'];
                window.location.href = nextLink ?? '/'
            })

            .catch((error) => {
                console.log(error);
                return error;
            })

    }

    logout() {

        return axios.request({
            url: '/authentication/authen/logout',
            method: 'post'
        })

            .then(response => {
                response = response['data'];
                window.location.href = '/'
            })

            .catch((error) => {
                console.log(error);
                return error;
            })

    }

    changeInfo(username, password) {

        return axios.request({
            url: '/authentication/authen/changeInfo',
            method: 'post',
            data: {
                username, password
            }
        })

            .then(response => {
                response = response['data'];
                if (response['result']) {
                    return response['data']
                }
                error_handle(response)
            })

            .catch((error) => {
                error_handle(error)
            })

    }
}

export default authen